import { SKU_SERVICE_URL } from '@/config';
import { CreateResponse, GetListResponse, type DataProvider } from '@refinedev/core';
import { SkuProductDetail } from '../types';

const apiUrl = SKU_SERVICE_URL;

export const skuProductDetailResourceName = 'sku-product-detail';

const skuProductDetailDataProvider: DataProvider = {
  getList: async () => {
    const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<GetListResponse<any>>((resolve) => {
      resolve({
        data,
        total: data.length ?? 0,
      });
    });
    return await response;
  },
  create: async ({ variables }) => {
    const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
    const timestamp = Date.now().toString();
    const payload = { id: timestamp, ...variables };
    data.push(payload);
    sessionStorage.setItem('product', JSON.stringify(data));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { id: timestamp } });
    });
    return await response;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update: async ({ resource, id, variables }) => {
    // find and replace data product fetch from getOne function
    const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
    const product = data.find((p: SkuProductDetail) => p.id === id);
    if (!product) {
      return Promise.reject({ message: 'Product not found' });
    }
    const index = data.indexOf(product);
    const payload = { id, ...variables };
    data[index] = { ...product, ...payload };
    sessionStorage.setItem('product', JSON.stringify(data));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { id } });
    });
    return await response;
  },
  getOne: async ({ id }) => {
    const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
    const product = data.find((p: SkuProductDetail) => p.id === id);
    if (!product) {
      return Promise.reject({ message: 'Product not found' });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: product });
    });
    return await response;
  },
  deleteOne: async ({ id }) => {
    const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
    const product = data.find((p: SkuProductDetail) => p.id === id);
    if (!product) {
      return Promise.reject({ message: 'Product not found' });
    }
    const index = data.indexOf(product);
    data.splice(index, 1);
    sessionStorage.setItem('product', JSON.stringify(data));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { id } });
    });
    return await response;

    // return await httpClient.delete(`${resource}/${id}`).then((r) => {
    //   return r.data;
    // });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default skuProductDetailDataProvider;

import { OMS_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = OMS_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const omsResourceName = 'oms-service';

const omsServiceProvider: Pick<DataProvider, 'getList' | 'getApiUrl'> = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'off') {
      params.limit = 9999;
    }

    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    return await httpClient.get(resource, { params }).then((r) => {
      return {
        data: r.data.data,
        total: r.data?.meta?.total_data | 0,
      };
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default omsServiceProvider;

import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const warehouseResourceName = 'warehouse';

const warehouseProvider: Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany' | 'getMany' | 'custom' | 'getList' | 'deleteOne' | 'getApiUrl'
> = {
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ resource, id, variables }) => {
    try {
      return await httpClient.put(`${resource}/${id}`, variables).then((response) => response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}`).then((response) => response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default warehouseProvider;

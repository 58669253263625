import type { RouteObject } from 'react-router-dom';
import { Buildings } from '@onefish/icons-react';
import { ResourceRouter } from '@/utils/type';

export const vendorResource: ResourceRouter[] = [
  {
    name: 'vendor',
    list: '/vendor',
    create: '/vendor/create',
    show: '/vendor/show/:id',
    edit: '/vendor/edit/:id',
    meta: {
      canDelete: true,
      label: 'Vendor Management',
      icon: <Buildings size={20} />,
      isEnable: true,
    },
  },
];

export const vendorRoutes: RouteObject = {
  path: 'vendor',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
    {
      path: 'create',
      lazy: () => import('./create'),
    },
    {
      path: 'show/:id',
      lazy: () => import('./show'),
    },
    {
      path: 'edit/:id',
      lazy: () => import('./edit'),
    },
  ],
};

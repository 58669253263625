import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { CreateResponse, type DataProvider } from '@refinedev/core';
import { PurchaseOrderDetail, PurchaseOrderSessionRequestPayload } from '../types';
import dayjs from 'dayjs';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const purchaseOrderSessionStorageResourceName = 'purchase-order-session-storage';

const purchaseOrderSessionStorageServiceProvider: DataProvider = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    return await httpClient.get(resource, { params }).then((r) => {
      return {
        data: r.data.data,
        total: r.data?.meta?.total_data | 0,
      };
    });
  },
  create: async ({ variables }: { variables: unknown }) => {
    const { uuid, ...rest } = variables as Partial<PurchaseOrderSessionRequestPayload>;
    const jsonString = sessionStorage.getItem(uuid ?? 'po');
    const data = jsonString ? JSON.parse(jsonString) : { ...rest, id: uuid };
    try {
      sessionStorage.setItem(uuid ?? 'po', JSON.stringify(data));
    } catch (e) {
      return Promise.reject(e);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { id: uuid } });
    });
    return await response;
  },
  update: async ({ id, variables }) => {
    // find and replace data product fetch from getOne function
    const data = JSON.parse(sessionStorage.getItem(String(id ?? '')) || '[]') ?? [];
    const payload = { ...data, ...variables };
    sessionStorage.setItem(String(id ?? ''), JSON.stringify(payload));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { id } });
    });
    return await response;
  },
  getOne: async ({ id }) => {
    const data = JSON.parse(sessionStorage.getItem(String(id ?? '')) || '[]') ?? [];
    // date conversion
    const estimate_date_arrival = data?.estimate_date_arrival ? dayjs(data?.estimate_date_arrival) : null;
    const purchase_order_detail = data?.purchase_order_detail?.map((sku: PurchaseOrderDetail) => {
      return { ...sku, sku_code_display: `[${sku.sku_code}] ${sku.sku_name}` };
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = new Promise<CreateResponse<any>>((resolve) => {
      resolve({ data: { ...data, estimate_date_arrival, purchase_order_detail } });
    });
    return await response;
  },
  deleteOne: async ({ resource, id }) => {
    return await httpClient.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default purchaseOrderSessionStorageServiceProvider;

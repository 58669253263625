/**
 * Override default Refine `notificationProvider` because the default design
 * doesn't match the design from design team
 *
 * The Refine default
 * https://github.com/refinedev/refine/blob/next/packages/antd/src/providers/notificationProvider/index.tsx
 */
import { NotificationProvider } from '@refinedev/core';
import { notification } from 'antd';

import { UndoableNotification, NotificationSuccessIcon, NotificationErrorIcon } from '@/components';

export const useNotificationProvider = () => {
  const [notify, notificationContextHolder] = notification.useNotification();

  const notificationProvider: NotificationProvider = {
    open: ({ key, message, description, type, cancelMutation, undoableTimeout }) => {
      if (type === 'progress') {
        notify.open({
          key,
          description: (
            <UndoableNotification
              notificationKey={key}
              message={message}
              cancelMutation={cancelMutation}
              undoableTimeout={undoableTimeout}
            />
          ),
          message: null,
          duration: 0,
          closeIcon: <></>,
        });
      } else {
        const icon = (() => {
          switch (type) {
            case 'error':
              return <NotificationErrorIcon />;
            default:
              return <NotificationSuccessIcon />;
          }
        })();

        notify.open({
          key,
          type,
          icon,
          description: message,
          message: description ?? null,
          placement: 'top',
        });
      }
    },
    close: (key) => {
      notification.destroy(key);
    },
  };

  return [notificationProvider, notificationContextHolder] as const;
};

const STORAGE_KEY = 'redirect_path';

export const setRedirectPath = (path?: string) => {
  if (path) {
    localStorage.setItem(STORAGE_KEY, path);
  }
};

export const getRedirectPath = () => localStorage.getItem(STORAGE_KEY);
export const deleteRedirectPath = () => localStorage.removeItem(STORAGE_KEY);

import { PROCUREMENT_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = PROCUREMENT_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const deliveryRequestResourceName = 'delivery-request';

const deliveryRequestDataProvider: Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany' | 'getMany' | 'custom' | 'getList' | 'create' | 'deleteOne' | 'update'
> = {
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}/${deliveryRequestResourceName}`).then((r) => {
        const data = r.data;
        return { ...data };
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
        This `any` is intened to receipt any type
      */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default deliveryRequestDataProvider;

import type { RouteObject } from 'react-router-dom';
import { ResourceRouter } from '@/utils/type';
import { Money } from '@onefish/icons-react';

export const vendorBillResource: ResourceRouter[] = [
  {
    name: 'vendor-bill',
    list: '/vendor-bill',
    show: '/vendor-bill/show/:id',
    meta: {
      label: 'Pembayaran Vendor',
      icon: <Money size={20} />,
      isEnable: true,
    },
  },
];

export const vendorBillRoutes: RouteObject = {
  path: 'vendor-bill',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
    {
      path: 'show/:id',
      lazy: () => import('./show'),
    },
  ],
};

const colors = {
  '--color-black': '#000000',
  '--color-white': '#ffffff',
  '--color-observatory-50': '#f3f9f7',
  '--color-observatory-70': '#e4f4f1',
  '--color-observatory-100': '#cde7e1',
  '--color-observatory-200': '#9acfc2',
  '--color-observatory-300': '#68b7a4',
  '--color-observatory-400': '#359f85',
  '--color-observatory-500': '#038767',
  '--color-observatory-600': '#026c52',
  '--color-observatory-700': '#02513e',
  '--color-observatory-800': '#01372a',
  '--color-observatory-900': '#01281f',
  '--color-aqua-50': '#ecfdf5',
  '--color-aqua-60': '#1aae9f',
  '--color-aqua-70': '#dffced',
  '--color-aqua-100': '#d1fae5',
  '--color-aqua-200': '#a7f3d0',
  '--color-aqua-300': '#6ee7b7',
  '--color-aqua-400': '#34d399',
  '--color-aqua-500': '#10b981',
  '--color-aqua-600': '#059669',
  '--color-aqua-700': '#047857',
  '--color-aqua-800': '#065f46',
  '--color-aqua-900': '#064e3b',
  '--color-green-50': '#f3f9f2',
  '--color-green-70': '#dfefdc',
  '--color-green-100': '#d1e7cc',
  '--color-green-200': '#a4d099',
  '--color-green-300': '#76b866',
  '--color-green-400': '#49a133',
  '--color-green-500': '#1b8900',
  '--color-green-600': '#166e00',
  '--color-green-700': '#105200',
  '--color-green-800': '#043900',
  '--color-green-900': '#002200',
  '--color-freshgreen-50': '#f7fee7',
  '--color-freshgreen-70': '#f2fddb',
  '--color-freshgreen-100': '#cde7e1',
  '--color-freshgreen-200': '#ecfccb',
  '--color-freshgreen-300': '#bef264',
  '--color-freshgreen-400': '#a3e635',
  '--color-freshgreen-500': '#84cc16',
  '--color-freshgreen-600': '#65a30d',
  '--color-freshgreen-700': '#4d7c0f',
  '--color-freshgreen-800': '#3f6212',
  '--color-freshgreen-900': '#365314',
  '--color-blue-50': '#eff6ff',
  '--color-blue-70': '#e6f1ff',
  '--color-blue-100': '#dbeafe',
  '--color-blue-200': '#bfdbfe',
  '--color-blue-300': '#93c5fd',
  '--color-blue-400': '#60a5fa',
  '--color-blue-500': '#3b82f6',
  '--color-blue-600': '#2563eb',
  '--color-blue-700': '#1d4ed8',
  '--color-blue-800': '#1e40af',
  '--color-blue-900': '#1e3a8a',
  '--color-red-50': '#fff1f2',
  '--color-red-60': '#d3455b',
  '--color-red-70': '#ffedee',
  '--color-red-100': '#ffe4e6',
  '--color-red-200': '#fecdd3',
  '--color-red-300': '#fda4af',
  '--color-red-400': '#fb7185',
  '--color-red-500': '#f43f5e',
  '--color-red-600': '#e11d48',
  '--color-red-700': '#be123c',
  '--color-red-800': '#9f1239',
  '--color-red-900': '#881337',
  '--color-orange-50': '#fff9f5',
  '--color-orange-70': '#fef0e5',
  '--color-orange-100': '#fde9d9',
  '--color-orange-200': '#fbd4b3',
  '--color-orange-300': '#f8be8e',
  '--color-orange-400': '#f49342',
  '--color-orange-500': '#c05717',
  '--color-orange-600': '#9e430b',
  '--color-orange-700': '#763208',
  '--color-orange-800': '#542103',
  '--color-orange-900': '#331102',
  '--color-yellow-50': '#fffbeb',
  '--color-yellow-70': '#fff7d8',
  '--color-yellow-100': '#fef3c7',
  '--color-yellow-200': '#fde68a',
  '--color-yellow-300': '#fcd34d',
  '--color-yellow-400': '#fbbf24',
  '--color-yellow-500': '#f59e0b',
  '--color-yellow-600': '#d97706',
  '--color-yellow-700': '#b45309',
  '--color-yellow-800': '#92400e',
  '--color-yellow-900': '#78350f',
  '--color-black-50': '#f6f6f6',
  '--color-black-70': '#e0e0e0',
  '--color-black-100': '#d2d2d2',
  '--color-black-200': '#a5a5a5',
  '--color-black-300': '#777777',
  '--color-black-400': '#4a4a4a',
  '--color-black-500': '#1d1d1d',
  '--color-black-600': '#171717',
  '--color-black-700': '#111111',
  '--color-black-800': '#0e0e0e',
  '--color-black-900': '#0d0d0d',
  '--color-grey-50': '#fafafa',
  '--color-grey-70': '#f5f5f5',
  '--color-grey-100': '#efefef',
  '--color-grey-200': '#e5e5e5',
  '--color-grey-300': '#d4d4d4',
  '--color-grey-400': '#a3a3a3',
  '--color-grey-500': '#737373',
  '--color-grey-600': '#525252',
  '--color-grey-700': '#404040',
  '--color-grey-800': '#262626',
  '--color-grey-900': '#171717',
  '--color-light-blue-50': '#f0f9ff',
  '--color-light-blue-70': '#eaf6ff',
  '--color-light-blue-100': '#e0f2fe',
  '--color-light-blue-200': '#bae6fd',
  '--color-light-blue-300': '#7dd3fc',
  '--color-light-blue-400': '#38bdf8',
  '--color-light-blue-500': '#0ea5e9',
  '--color-light-blue-600': '#0284c7',
  '--color-light-blue-700': '#0369a1',
  '--color-light-blue-800': '#075985',
  '--color-light-blue-900': '#0c4a6e',
  '--color-purple-50': '#faf5ff',
  '--color-purple-70': '#f7efff',
  '--color-purple-100': '#f3e8ff',
  '--color-purple-200': '#e9d5ff',
  '--color-purple-300': '#d8b4fe',
  '--color-purple-400': '#c084fc',
  '--color-purple-500': '#a855f7',
  '--color-purple-600': '#9333ea',
  '--color-purple-700': '#7e22ce',
  '--color-purple-800': '#6b21a8',
  '--color-purple-900': '#581c87',
  '--color-pink-50': '#fdf2f8',
  '--color-pink-70': '#fdeff7',
  '--color-pink-100': '#fce7f3',
  '--color-pink-200': '#fbcfe8',
  '--color-pink-300': '#f9a8d4',
  '--color-pink-400': '#f472b6',
  '--color-pink-500': '#ec4899',
  '--color-pink-600': '#db2777',
  '--color-pink-700': '#be185d',
  '--color-pink-800': '#9d174d',
  '--color-pink-900': '#831843',
};

export const colorAlias = {
  '--primary-color': colors['--color-observatory-500'],
  '--success-color': colors['--color-observatory-500'],
  '--warning-color': colors['--color-orange-400'],
  '--error-color': colors['--color-red-500'],
  '--highlight-color': colors['--color-red-500'],
  '--text-color': colors['--color-black-400'],
};

export default colors;

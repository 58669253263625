import { PROCUREMENT_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = PROCUREMENT_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const analyticTagsResourceName = 'analytic-tags';

const analyticTagsProvider: Omit<
  Required<DataProvider>,
  | 'update'
  | 'create'
  | 'deleteOne'
  | 'getApiUrl'
  | 'getOne'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'getMany'
  | 'custom'
> = {
  getList: async ({ resource, filters }) => {
    const params: Record<string, string | number> = {};

    filters?.forEach((f) => 'field' in f && (params[f.field] = f.value));

    return await httpClient.get(resource, { params }).then((r) => ({
      data: r.data.data,
      total: r.data?.meta?.total_data || 0,
    }));
  },
};

export default analyticTagsProvider;

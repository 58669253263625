import { useWarnAboutChange } from '@refinedev/core';

import { useCallback } from 'react';
import { Modal } from '@efishery/onefish-antd';

import { NotificationWarningIcon } from '@/components';

export const useRefineWarnModal = () => {
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const [modal, contextHolder] = Modal.useModal();

  const warnOnCallback = useCallback(
    (callback: () => void) => {
      if (warnWhen) {
        modal.confirm({
          title: 'Do you want to leave?',
          content: 'Data that you inputted may not be saved.',
          okType: 'danger',
          okText: 'Leave',
          cancelText: 'Cancel',
          centered: true,
          icon: <NotificationWarningIcon />,
          onOk: () => {
            setWarnWhen(false);
            callback();
          },
        });
      } else {
        callback();
      }
    },
    [warnWhen, setWarnWhen, modal],
  );

  return { warnOnCallback, contextHolder };
};

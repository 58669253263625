import type { RouteObject } from 'react-router-dom';
import { BoxContainer } from '@onefish/icons-react';
import { ResourceRouter } from '@/utils/type';

export const warehouseResource: ResourceRouter[] = [
  {
    name: 'warehouse',
    list: '/warehouse',
    create: '/warehouse/create',
    show: '/warehouse/show/:id',
    edit: '/warehouse/edit/:id',
    meta: {
      canDelete: true,
      label: 'Warehouse',
      icon: <BoxContainer size={20} />,
      isEnable: true,
    },
  },
];

export const warehouseRoutes: RouteObject = {
  path: 'warehouse',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
    {
      path: 'create',
      lazy: () => {
        return import('./create');
      },
    },
    {
      path: 'show/:id',
      lazy: () => import('./show'),
    },
    {
      path: 'edit/:id',
      lazy: () => import('./edit'),
    },
  ],
};

import { WMS_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = WMS_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const wmsResourceName = 'wms';

const wmsDataProvider: DataProvider = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'off') {
      params._limit = 9999;
      params.is_show_all_warehouse = 'true';
    }

    if (mode === 'server') {
      params._page = current;
      params._limit = pageSize;
      params.is_show_all_warehouse = 'true';
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    const res = await httpClient.get(resource, { params });

    const data = res?.data?.data;
    const total = res?.data?.pagination?.total_elements ?? data?.length;

    return {
      data,
      total,
    };
  },
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ resource, id, variables }) => {
    try {
      return await httpClient.put(`${resource}/${id}`, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}`).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  deleteOne: async ({ resource, id }) => {
    return await httpClient.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default wmsDataProvider;

/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src/main/src/dataProviders/authDataProvider.ts
 */
import { AuthBindings } from '@refinedev/core';
import { auth } from '@/utils/auth';
import { deleteRedirectPath } from './store';
import { IS_TESTING_ENV } from '@/config';

export const authProvider: AuthBindings = {
  login: async ({ grantType, code, redirectTo }) => {
    // Add initialize cookie key condition with flag
    if (!IS_TESTING_ENV) {
      await auth.initializeCookieKeys();
    }

    try {
      const { accessToken } = await auth.persistExchange(code, grantType);
      await auth.getProfile(accessToken);
      const origin = window.location.origin;

      window.location.assign(`${origin}${redirectTo}`);
      deleteRedirectPath();

      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        redirectTo: '/login',
      };
    }
  },
  logout: async () => {
    await auth.logout();

    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const isLoggedIn = await auth.isSignedAsLogged();

    if (!isLoggedIn) {
      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: true,
    };
  },
  getIdentity: async () => {
    const accessToken = await auth.getAccessToken();
    const profile = await auth.getProfile(accessToken as string);

    return profile.data;
  },
  onError: async (error) => {
    return { error };
  },
};

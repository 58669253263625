import type { RouteObject } from 'react-router-dom';
import { Buildings } from '@onefish/icons-react';
import { ResourceRouter } from '@/utils/type';

export const fakturPajakResource: ResourceRouter[] = [
  {
    name: 'faktur-pajak',
    list: '/faktur-pajak',
    create: '/faktur-pajak/create',
    show: '/faktur-pajak/show/:id',
    edit: '/faktur-pajak/edit/:id',
    meta: {
      canDelete: true,
      label: 'Faktur Pajak',
      icon: <Buildings size={20} />,
      isEnable: true,
    },
  },
];

export const fakturPajakRoutes: RouteObject = {
  path: 'faktur-pajak',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
    {
      path: 'create',
      lazy: () => {
        return import('./create');
      },
    },
    {
      path: 'show/:id',
      lazy: () => import('./show'),
    },
  ],
};

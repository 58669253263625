import { SKU_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';
import { SKUImagesFile } from '../types';

const apiUrl = SKU_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const skuResourceName = 'sku';

const skuDataProvider: DataProvider = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    if (mode === 'off') {
      params.limit = 9999;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    return await httpClient.get(resource, { params }).then((r) => {
      return {
        data: r.data.data,
        total: r.data?.meta?.total_data | 0,
      };
    });
  },
  create: async ({ resource, variables, meta }) => {
    if (meta?.method === 'patch') {
      return await httpClient.patch(resource, variables).then((r) => {
        return r.data;
      });
    }

    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ resource, variables }) => {
    try {
      return await httpClient.post(resource, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}`).then(async (r) => {
        const resp = await r.data;
        // product details
        const data = JSON.parse(sessionStorage.getItem('product') || '[]') ?? [];
        if (data.length === 0 && resp?.data?.sku_product_details?.length > 0) {
          await sessionStorage.setItem(
            'product',
            JSON.stringify(
              (resp?.data?.sku_product_details ?? []).map((item: { id: string; name: string; detail: string }) => ({
                id: item?.id,
                name: item?.name,
                detail: item?.detail,
              })),
            ),
          );
        }

        // sku images
        const skuImages: Partial<SKUImagesFile>[] = (resp?.data?.sku_images ?? []).map(
          (item: { attachment: string; is_primary: boolean }) => ({
            is_primary: item?.is_primary,
            response: {
              location: item?.attachment,
            },
          }),
        );

        return await httpClient.get('brand/' + resp?.data?.brand_code).then(async (r) => {
          return await {
            ...resp,
            data: {
              ...resp.data,
              customer_tax: resp?.data?.sku_detail?.customer_tax,
              width_in_cm: resp?.data?.sku_detail?.width_in_cm,
              length_in_cm: resp?.data?.sku_detail?.length_in_cm,
              height_in_cm: resp?.data?.sku_detail?.height_in_cm,
              weight: resp?.data?.sku_detail?.weight,
              weight_uom: resp?.data?.sku_detail?.weight_uom,
              sku_product_nutritions: resp?.data?.sku_nutritions,
              sku_images_attachment: skuImages,
              brand_name: r.data?.data?.brand_name,
            },
          };
        });
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  deleteOne: async ({ resource, id }) => {
    return await httpClient.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default skuDataProvider;

import type { RouteObject } from 'react-router-dom';
import { NewspaperClipping } from '@onefish/icons-react';
import { ResourceRouter } from '@/utils/type';

export const purchaseOrderResource: ResourceRouter[] = [
  {
    name: 'purchase-order',
    list: '/purchase-order',
    create: '/purchase-order/create',
    show: '/purchase-order/show/:id',
    edit: '/purchase-order/edit/:id',
    meta: {
      canDelete: true,
      label: 'Purchase Order',
      icon: <NewspaperClipping size={20} />,
      isEnable: true,
    },
  },
];

export const purchaseOrderRoutes: RouteObject = {
  path: 'purchase-order',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
    {
      path: 'create',
      lazy: () => import('./create'),
    },
    {
      path: 'show/:id',
      lazy: () => import('./show'),
    },
    {
      path: 'edit/:id',
      lazy: () => import('./edit'),
    },
  ],
};

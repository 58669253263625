import { AUTH_USER_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = AUTH_USER_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const authUserResourceName = 'auth-user';

const authUserProvider: Omit<
  Required<DataProvider>,
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'getMany'
  | 'custom'
  | 'update'
  | 'create'
  | 'deleteOne'
  | 'getApiUrl'
  | 'getOne'
> = {
  getList: async ({ resource, filters }) => {
    const params: Record<string, string | number> = {};

    filters?.forEach((filter) => 'field' in filter && (params[filter.field] = filter.value));

    return await httpClient.get(resource, { params }).then((response) => ({
      data: response.data.data,
      total: response.data?.meta?.total_data || 0,
    }));
  },
};

export default authUserProvider;

/**
 * This function is copied from `octo-fish-internal-tools`
 * https://bitbucket.org/efishery/fish-internal-tool/src/master/frontend/src/libs/utils/*
 */
export const capitalize = (s: string) => {
  return (s && s[0].toUpperCase() + s.slice(1)) || '';
};

export const capitalizeFirstLetter = (s: string) => {
  return (s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()) || '';
};

export const normalizeValue = (s: string) => {
  const value = s.replace(/_/g, ' ');
  return capitalizeFirstLetter(value);
};

export const unslug = (slug: string) => {
  const words = slug.split('-');

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
};

export const combineString = (baseString: string, addonStrings: (string | null | undefined)[], separator?: string) => {
  const arrayOfString = [baseString, ...addonStrings];

  return arrayOfString.filter((str) => str !== '' && str !== undefined && str !== null).join(separator || '');
};

export const extractFileExt = (url: string) => {
  const splitted = url.split('.');
  return `.${splitted[splitted.length - 1]}`;
};

export const extractFileName = (url: string) => {
  return url ? url.split('/').pop() : '';
};

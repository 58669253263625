import { brandResourceName } from './brand/providers/brandServiceProvider';
import { enumTagResourceName } from './enum-tag/providers/enumTagServiceProvider';
import { categoryResourceName } from './category/providers/categoryServiceProvider';
import { skuResourceName } from './sku/providers/skuServiceProvider';
import { uomResourceName } from './uom/providers/uomServiceProvider';
import { Folder } from '@onefish/icons-react';
import type { RouteObject } from 'react-router-dom';
import { ResourceRouter } from '@/utils/type';

const skuManagementResourceName = 'sku-management';

export const skuManagementResource: ResourceRouter[] = [
  {
    name: skuManagementResourceName,
    meta: {
      canDelete: true,
      label: 'SKU Management',
      icon: <Folder size={20} />,
      isEnable: true,
    },
  },
  {
    name: skuResourceName,
    list: '/sku-management/sku',
    show: '/sku-management/sku/show/:id',
    create: '/sku-management/sku/create',
    edit: '/sku-management/sku/edit/:id',
    meta: {
      label: 'SKU',
      parent: skuManagementResourceName,
      isEnable: true,
    },
  },
  {
    name: brandResourceName,
    list: '/sku-management/brand',
    meta: {
      label: 'Merk',
      parent: skuManagementResourceName,
      isEnable: true,
    },
  },
  {
    name: uomResourceName,
    list: '/sku-management/uom',
    meta: {
      label: 'UOM',
      parent: skuManagementResourceName,
      isEnable: true,
    },
  },
  {
    name: categoryResourceName,
    list: '/sku-management/category',
    meta: {
      label: 'Category',
      parent: skuManagementResourceName,
      isEnable: true,
    },
  },
  {
    name: enumTagResourceName,
    list: '/sku-management/enum-tag',
    create: '/sku-management/enum-tag/create',
    meta: {
      label: 'ENUM Tag',
      parent: skuManagementResourceName,
      isEnable: true,
    },
  },
];

export const skuManagementRoutes: RouteObject = {
  path: skuManagementResourceName,
  children: [
    {
      path: skuResourceName,
      children: [
        {
          index: true,
          lazy: () => import('./sku/list'),
        },
        {
          path: 'show/:id',
          lazy: () => import('./sku/show'),
        },
        {
          path: 'create',
          lazy: () => import('./sku/create'),
        },
        {
          path: 'edit/:id',
          lazy: () => import('./sku/edit'),
        },
      ],
    },
    {
      path: brandResourceName,
      children: [
        {
          index: true,
          lazy: () => import('./brand/list'),
        },
      ],
    },
    {
      path: uomResourceName,
      children: [
        {
          index: true,
          lazy: () => import('./uom/list'),
        },
      ],
    },
    {
      path: 'category',
      children: [
        {
          index: true,
          lazy: () => import('./category/list'),
        },
      ],
    },
    {
      path: enumTagResourceName,
      children: [
        {
          index: true,
          lazy: () => import('./enum-tag/list'),
        },
        {
          path: 'create',
          lazy: () => import('./enum-tag/list'),
        },
      ],
    },
  ],
};

import { ENABLE_TOGGLE_DARK_MODE } from '@/config';
import randomAvatar from '@/images/random-avatar.svg';
import type { UserProfile } from '@efishery/sdk-core';
import { useThemedLayoutContext, type RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useGetIdentity } from '@refinedev/core';
import { Layout as AntdLayout, Avatar, Button, Space, Switch, Typography, theme } from 'antd';
import React, { useContext } from 'react';
import { ColorModeContext } from '@/utils/contexts/color-mode';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import breakpoints from '@/utils/styles/breakpoints';

const { Text } = Typography;
const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky }) => {
  const { siderCollapsed, setSiderCollapsed, setMobileSiderOpen } = useThemedLayoutContext();
  const { token } = useToken();
  const { data: user } = useGetIdentity<UserProfile>();
  const { mode, setMode } = useContext(ColorModeContext);

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: window.innerWidth >= parseInt(breakpoints.tablet) ? '0px 24px 0px 15px' : '0px 15px',
    height: '64px',
    borderBottom: '1px solid var(--color-black-100)',
  };

  if (sticky) {
    headerStyles.position = 'sticky';
    headerStyles.top = 0;
    headerStyles.zIndex = 9999;
  }

  const onClick = () => {
    if (window.innerWidth >= parseInt(breakpoints.tablet)) {
      setSiderCollapsed(!siderCollapsed);
    } else {
      setMobileSiderOpen(true);
    }
  };

  return (
    <AntdLayout.Header style={headerStyles}>
      <div style={{ display: 'flex' }}>
        <Button
          type="text"
          onClick={onClick}
          icon={
            siderCollapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: '24px', color: '#038767' }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: '24px', color: '#038767' }} />
            )
          }
        />
      </div>
      <Space>
        {ENABLE_TOGGLE_DARK_MODE ? (
          <Switch
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => {
              setMode(mode === 'light' ? 'dark' : 'light');
            }}
            defaultChecked={mode === 'light'}
          />
        ) : (
          <Space style={{ marginLeft: '8px' }} size="middle">
            {user?.full_name && <Text strong>{user.full_name}</Text>}
            <Avatar src={randomAvatar} />
          </Space>
        )}
      </Space>
    </AntdLayout.Header>
  );
};

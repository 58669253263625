import MaintenanceImg from '@/images/maintenis.png';
import './styles.css';

const MaintenancePage = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <img src={MaintenanceImg} alt="maintenis" width={500} />
        <h1>Under Maintenance</h1>
        <p>We apologize for the inconvenience, but our site is currently undergoing maintenance.</p>
        <p>Please check back later.</p>
        <b>eFishery Team</b>
      </div>
    </div>
  );
};

export default MaintenancePage;

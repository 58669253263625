import type { RouteObject } from 'react-router-dom';

export const authRoutes: RouteObject[] = [
  {
    path: '/login',
    lazy: () => {
      return import('./login');
    },
  },
  {
    path: '/redirect',
    lazy: () => {
      return import('./redirect');
    },
  },
];

import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const fakturPajakResourceName = 'faktur-pajak';

const fakturPajakDataProvider: DataProvider = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'off') {
      params._limit = 9999;
    }

    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    return await httpClient.get(resource, { params }).then((r) => {
      return {
        data: r.data.data,
        total: r.data?.meta?.total_data | 0,
      };
    });
  },
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ resource, id, variables, meta }) => {
    try {
      if (meta?.method === 'patch') {
        return await httpClient.patch(resource, variables).then((r) => {
          return r.data;
        });
      }

      if (meta?.method === 'post') {
        return await httpClient.post(resource, variables).then((r) => {
          return r.data;
        });
      }

      return await httpClient.put(`${resource}/${id}`, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}`).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  deleteOne: async ({ resource, id }) => {
    return await httpClient.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
  custom: async ({ url, method, payload, headers, meta }) => {
    const requestUrl = `${url}?`;

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
        if (meta && meta.export === true) {
          axiosResponse = await httpClient.get(requestUrl, { headers });
        } else {
          axiosResponse = await httpClient[method](url, payload, { headers });
        }
        break;
      case 'patch':
        axiosResponse = await httpClient[method](url, payload, { headers });
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, { headers });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, { headers });
        break;
    }

    const res = axiosResponse;

    return Promise.resolve(res.data);
  },
};

export default fakturPajakDataProvider;

import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const vendorSkuEbpResourceName = 'vendor-sku-ebp';

const vendorSkuEbpDataProvider: Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany' | 'getMany' | 'deleteOne' | 'getOne' | 'update'
> = {
  getList: async ({ resource }) => {
    return await httpClient.get(resource).then((r) => {
      return {
        data: r.data.data.sku,
        total: r.data.data.total | 0,
      };
    });
  },
  // submit / update list SKU
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
  custom: async ({ url, method, payload, headers, meta }) => {
    const requestUrl = `${url}?`;

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
        if (meta && meta.export === true) {
          axiosResponse = await httpClient.get(requestUrl, { headers });
        } else {
          axiosResponse = await httpClient[method](url, payload, { headers });
        }
        break;
      case 'patch':
        axiosResponse = await httpClient[method](url, payload, { headers });
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, { headers });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, { headers });
        break;
    }

    const res = axiosResponse;

    return Promise.resolve(res.data);
  },
};

export default vendorSkuEbpDataProvider;

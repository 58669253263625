import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const vendorSkuResourceName = 'vendor-sku';

const vendorSkuDataProvider: DataProvider = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });

    return await httpClient.get(resource, { params }).then((r) => {
      return {
        data: r.data.data.sku,
        total: r.data.data.total | 0,
      };
    });
  },
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ id, variables }) => {
    try {
      return await httpClient.post(`vendor/${id}/sku`, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getOne: async ({ id }) => {
    try {
      return await httpClient.get(`vendor/${id}/sku`).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  deleteOne: async ({ resource, id }) => {
    return await httpClient.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};

export default vendorSkuDataProvider;

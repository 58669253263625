import { PROCUREMENT_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = PROCUREMENT_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const vendorBillResourceName = 'vendor-bill';

const vendorBillProvider: Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany' | 'getMany' | 'custom' | 'deleteOne' | 'getApiUrl' | 'create'
> = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    const { pageSize = 10, current = 1, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.limit = pageSize;
      params.page = current;
    }

    filters?.forEach((f) => {
      if ('field' in f) {
        params[f['field']] = f.value;
      }
    });

    return await httpClient.get(resource, { params }).then((res) => {
      return {
        summary: res.data.data?.summary,
        data: res.data.data?.list,
        total: res.data?.meta?.total_data | 0,
      };
    });
  },
  update: async ({ resource, id, variables, meta }) => {
    try {
      if (meta?.method === 'patch') {
        return await httpClient.patch(resource, variables).then((r) => {
          return r.data;
        });
      }

      if (meta?.method === 'post') {
        return await httpClient.post(resource, variables).then((r) => {
          return r.data;
        });
      }

      return await httpClient.put(`${resource}/${id}`, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
  getOne: async ({ resource, id, meta }) => {
    try {
      return await httpClient.get(`${resource}/${id}`, { params: meta?.params }).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
};

export default vendorBillProvider;

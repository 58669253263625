import type { RouteObject } from 'react-router-dom';
import { NewspaperClipping } from '@onefish/icons-react';
import { ResourceRouter } from '@/utils/type';

export const poDropshipResource: ResourceRouter[] = [
  {
    name: 'po-dropship__purchase-order',
    list: '/purchase-order-dropship',
    show: '/purchase-order-dropship/show/:id',
    meta: {
      label: 'PO Dropship',
      icon: <NewspaperClipping size={20} />,
      isEnable: true,
    },
  },
];

export const poDropshipRoutes: RouteObject = {
  path: 'purchase-order-dropship',
  children: [
    {
      children: [
        {
          index: true,
          lazy: () => {
            return import('./list');
          },
        },
        {
          path: 'show/:id',
          lazy: () => import('./show'),
        },
      ],
    },
  ],
};

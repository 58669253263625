import { WarningCircle, XCircle, CheckCircle, ArrowLeft } from '@onefish/icons-react';
import type { IconProps } from '@onefish/icons-react';

import { ElementType, ComponentPropsWithoutRef } from 'react';

// this component made because icon from `@onefish/icons-react`
// returning <svg/> and somethimes the icon is not align center
// so to make the icon align center everytime use it instead'
// of makeing redundant style better to create wrapper for it
export const Icon = <T extends ElementType = 'svg'>(
  props: {
    as?: T;
  } & ComponentPropsWithoutRef<T>,
) => {
  const { as: Comp = 'svg', 'data-testid': dataTestId, ...restProps } = props;
  return (
    <span className="anticon" data-testid={dataTestId}>
      <Comp {...restProps}></Comp>
    </span>
  );
};

export const NotificationWarningIcon = (props: IconProps) => {
  return <Icon as={WarningCircle} {...props} />;
};

export const NotificationSuccessIcon = (props: IconProps) => {
  return <Icon as={CheckCircle} weight="fill" color="var(--primary-color)" {...props} />;
};

export const NotificationErrorIcon = (props: IconProps) => {
  return <Icon as={XCircle} weight="fill" color="var(--color-red-600)" {...props} />;
};

export const DrawerCloseIcon = (props: IconProps) => {
  return <Icon as={ArrowLeft} {...props} />;
};
